<template>
  <v-container fluid class="wLightGray secondary-container">
    <div
      class="secondary-container"
      :class="$vuetify.breakpoint.lgAndUp ? 'line' : ''"
    >
      <router-link to="/mis-membresias" class="font-weight-bold">
        <v-icon> mdi-chevron-left </v-icon>
        <span> Volver a mis membresías </span>
      </router-link>
    </div>
    <v-sheet class="rounded-xl mb-15 mb-md-0 mt-lg-0 mt-4 secondary-container">
      <v-row
        :class="
          $vuetify.breakpoint.lgAndUp ? 'container-85-vh' : 'container-75-vh'
        "
      >
        <v-col cols="12" class="px-8 pt-5">
          <h2 class="wDarkerBlue--text font-weight-black">
            Comencemos a configurar tu membresía
          </h2>
        </v-col>
        <v-col cols="12">
          <v-row class="justify-center">
            <v-col cols="12" md="8" lg="6">
              <v-card tile elevation="0" class="align--transform pb-6">
                <v-form v-model="validCreateAffiliationForm" ref="createAffiliationForm">
                  <v-container>
                    <fieldset v-if="step === 1">
                      <v-col cols="12">
                        <span class="font-weight-bold">¿Qué nombre le quieres dar?</span>
                      </v-col>
                      <v-col cols="12">
                        <span
                          >Ponle nombre que capte la atención de tu
                          audiencia</span
                        >
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Nombre de membresía"
                          v-model="form.name"
                          placeholder="Ejemplo. Integrantes de WeChamber"
                          filled
                          rounded
                          :rules="affiliationNameRules"
                        ></v-text-field>
                      </v-col>
                    </fieldset>
                    <fieldset v-if="step === 2">
                      <v-col cols="12">
                        <span class="font-weight-bold"
                          >Describe brevemente lo que ofrece tu organización</span
                        >
                      </v-col>
                      <v-col cols="12">
                        <span>En los siguientes pasos podrás describir los pasos puntuales de cada nivel de tu membresía</span>
                      </v-col>
                      <v-col cols="12">
                        <tiptap-vuetify
                          v-model="form.description"
                          :extensions="extensions"
                          placeholder="Descripción"
                          :toolbar-attributes="{ color: 'primary', rounded: 't-lg', dark: true }"
                          :card-props="{ elevation: '0', rounded: 'lg', color: '#f0f0f0'}"
                        />
                      </v-col>
                    </fieldset>
                    <fieldset v-if="step === 3">
                      <v-row>
                        <v-col cols="12">
                          <span class="font-weight-bold"
                            >¿Qué configuración de pagos tendrá tu membresía?</span
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            >Activa o desactiva las diferentes opciones, según
                            apliquen para tu proyecto.</span
                          >
                        </v-col>
                        <v-col cols="12">
                          <v-row class="mx-1 mb-3">
                            <v-col cols="12" class="pb-0">
                              <v-row>
                                <v-switch
                                  color="primary"
                                  v-model="form.paymentModality"
                                  label="¿La membresía es gratuita?"
                                  @change="
                                    form.paymentModality
                                      ? (form.installments = false)
                                      : ''
                                  "
                                ></v-switch>
                                <v-tooltip top max-width="350px">
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                      color="primary"
                                      class="ml-2"
                                      dark
                                      v-on="on"
                                      >mdi-help-circle</v-icon
                                    >
                                  </template>
                                  <span
                                    >Activa esta opción si tu membresía es
                                    gratuita.</span
                                  >
                                </v-tooltip>
                              </v-row>
                            </v-col>
                            <v-col
                              cols="12"
                              class="pb-0"
                              v-if="!form.paymentModality"
                            >
                              <v-row>
                                <v-switch
                                  label="¿Se aceptan meses sin intereses?"
                                  color="primary"
                                  v-model="form.installments"
                                ></v-switch>
                              </v-row>
                              <p v-if="form.installments" class="body-2">
                                Aplica sobretasa al ofrecer MSI
                                <small>
                                  <a
                                    href="https://stripe.com/es-mx/pricing/local-payment-methods"
                                    target="_blank"
                                    >Más información</a>
                                </small>
                              </p>
                            </v-col>
                            <v-col
                              cols="12"
                              class="pb-0"
                              v-if="!form.paymentModality"
                            >
                              <v-row>
                                <v-switch
                                  label="¿Ofrecerás la opción de facturar?"
                                  color="primary"
                                  v-model="form.offerBilling"
                                ></v-switch>
                                <v-tooltip top max-width="350px">
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                      color="primary"
                                      class="ml-2"
                                      dark
                                      v-on="on"
                                      >mdi-help-circle</v-icon
                                    >
                                  </template>
                                  <span
                                    >Al activar esta función, habilitas los
                                    campos para que las personas que requieran
                                    factura puedan dejar sus datos, mismos que
                                    podrás consultar desde tu tablero. Recuerda
                                    que quien emite la factura eres tú como
                                    organización que ofrece la membresía.</span
                                  >
                                </v-tooltip>
                              </v-row>
                            </v-col>
                            <!--
                            <v-col
                              cols="12"
                              class="pb-0"
                              v-if="!form.paymentModality"
                            >
                              <v-row>
                                <v-switch
                                  label="¿Deseas trasladar la comisión de la pasarela de pago al afiliado?"
                                  color="primary"
                                  v-model="form.comissionTransfer"
                                ></v-switch>
                                <v-tooltip top max-width="350px">
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                      color="primary"
                                      class="ml-2"
                                      dark
                                      v-on="on"
                                      >mdi-help-circle</v-icon
                                    >
                                  </template>
                                  <span
                                    >Al activar esta opción se le aumentará la
                                    comisión de la pasarela de pago al total de
                                    la orden del asistente. Cada opción de pago
                                    tiene diferentes comisiones.</span
                                  >
                                </v-tooltip>
                              </v-row>
                            </v-col>-->
                          </v-row>
                        </v-col>
                      </v-row>
                    </fieldset>
                    <fieldset v-if="step === 4">
                      <v-col>
                        <v-row>
                          <span class="font-weight-bold"
                            >Ahora configuremos los niveles de membresía que deseas ofrecer</span
                          >
                        </v-row>
                        <v-row>
                          <span
                            >Recuerda que puedes configurar hasta (x)
                            cantidad.</span
                          >
                        </v-row>
                      </v-col>
                      <v-row class="align-center">
                        <v-col cols="12" class="d-flex justify-end mt-3">
                          <v-btn
                            v-if="form.affiliationTypes.length > 1"
                            color="red"
                            fab
                            small
                            @click="deleteLevel()"
                            ><v-icon color="white">mdi-close</v-icon></v-btn
                          >
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center">
                          <p
                              class="
                                accent--text
                                font-weight-bold
                                mb-0
                              "
                              >Nivel {{ levelCounter + 1 }}</p
                            >
                        </v-col>
                        <v-col cols="2" class="d-flex justify-end">
                          <!--<v-sheet
                            rounded
                            class="px-2 py-6"
                            color="wLightAccent"
                          >
                            <span
                              class="
                                orientation--text
                                accent--text
                                font-weight-bold
                              "
                              >ENTRADA {{ levelCounter + 1 }}</span
                            >
                          </v-sheet>
                          <br />-->
                          <v-btn
                            v-if="
                              form.affiliationTypes.length == levelCounter + 1 &&
                              form.affiliationTypes.length > 1 &&
                              levelCounter > 100
                            "
                            class="mx-2"
                            rounded
                            max-height="60"
                            max-width="15"
                            min-height="50"
                            min-width="10"
                            dark
                            small
                            color="primary"
                            @click="deleteLevel()"
                          >
                            <v-icon dark> mdi-minus </v-icon>
                          </v-btn>

                          <v-tooltip v-else-if="levelCounter > 0 && form.affiliationTypes.length > 1" top max-width="250">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mx-2"
                                rounded
                                max-height="60"
                                max-width="15"
                                min-height="50"
                                min-width="10"
                                dark
                                small
                                v-bind="attrs"
                                v-on="on"
                                color="primary"
                                @click="prevLevel()"
                              >
                                <v-icon dark> mdi-chevron-left </v-icon>
                              </v-btn>
                            </template>
                            <span v-if="validCreateAffiliationForm">Acceso anterior</span>
                            <span v-else
                              >Verifica que hayas llenado los datos
                              correctamente para poder retroceder</span
                            >
                          </v-tooltip>
                        </v-col>
                        <v-col cols="8">
                          <v-list>
                            <v-list-item>
                              <v-text-field
                                v-model="form.affiliationTypes[levelCounter].name"
                                label="Nombre"
                                filled
                                rounded
                                :rules="levelNameRules"
                              ></v-text-field>
                            </v-list-item>
                            <v-list-item v-if="!form.paymentModality">
                              <v-text-field
                                v-model="form.affiliationTypes[levelCounter].price"
                                label="precio"
                                type="number"
                                prefix="$"
                                filled
                                rounded
                                :rules="levelPriceRules"
                                min="0"
                              ></v-text-field>
                            </v-list-item>
                            <v-list-item>
                              <v-select
                                v-model="form.affiliationTypes[levelCounter].period"
                                label="Periodo de renovación"
                                filled
                                rounded
                                :items="affiliationPeriods"
                              ></v-select>
                            </v-list-item>
                            <v-list-item>
                              <v-textarea
                                v-model="form.affiliationTypes[levelCounter].details"
                                label="Describe los beneficios de este nivel de membresía"
                                filled
                                rounded
                              ></v-textarea>
                            </v-list-item>
                          </v-list>
                        </v-col>
                        <v-col cols="2">
                          <v-tooltip v-if="form.affiliationTypes.length == levelCounter + 1" top max-width="250">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mx-2"
                                rounded
                                max-height="60"
                                max-width="15"
                                min-height="50"
                                min-width="10"
                                dark
                                small
                                v-bind="attrs"
                                v-on="on"
                                color="primary"
                                @click="addLevel()"
                              >
                                <v-icon dark> mdi-plus </v-icon>
                              </v-btn>
                            </template>
                            <span v-if="validCreateAffiliationForm">Agregar acceso</span>
                            <span v-else
                              >Verifica que hayas llenado los datos
                              correctamente para poder agregar un acceso</span
                            >
                          </v-tooltip>

                          <v-tooltip v-else top max-width="250">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mx-2"
                                rounded
                                max-height="60"
                                max-width="15"
                                min-height="50"
                                min-width="10"
                                dark
                                small
                                v-bind="attrs"
                                v-on="on"
                                color="primary"
                                @click="nextLevel()"
                              >
                                <v-icon dark> mdi-chevron-right </v-icon>
                              </v-btn>
                            </template>
                            <span v-if="validCreateAffiliationForm">Siguiente acceso</span>
                            <span v-else
                              >Verifica que hayas llenado los datos
                              correctamente para poder avanzar</span
                            >
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </fieldset>
                    <fieldset v-if="step === 5">
                      <v-row>
                        <v-col cols="12">
                          <span class="font-weight-bold"
                            >Último paso de la creación de la membresía.</span
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            >Has finalizado la creación de tu membresía da clic en
                            el botón de continuar.</span
                          >
                        </v-col>
                        <v-col class="d-flex justify-center">
                          <v-btn
                            class="font-weight-bold"
                            color="secondary"
                            @click="_createAffiliation()"
                            rounded
                            elevation="0"
                            :loading="createEventButtonLoader"
                            >Continuar</v-btn
                          >
                        </v-col>
                      </v-row>
                    </fieldset>
                    <v-row class="mt-3 mb-8" justify="space-between">
                      <v-col cols="12" class="d-flex justify-space-between">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :disabled="step == 1"
                              class="mx-2"
                              fab
                              small
                              color="accent"
                              v-bind="attrs"
                              v-on="on"
                              @click="prev()"
                            >
                              <v-icon dark> mdi-chevron-left </v-icon>
                            </v-btn>
                          </template>
                          <span>Regresar</span>
                        </v-tooltip>
                        <v-tooltip top max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :disabled="step == 5"
                              class="mx-2"
                              fab
                              small
                              color="accent"
                              v-bind="attrs"
                              v-on="on"
                              @click="next()"
                            >
                              <v-icon dark> mdi-chevron-right </v-icon>
                            </v-btn>
                          </template>
                          <span v-if="validCreateAffiliationForm">Siguiente</span>
                          <span v-else
                            >Verifica que hayas llenado los datos correctamente
                            para poder avanzar</span
                          >
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row :class="step === 3 ? '' : 'pt-16'">
                      <v-col cols="12" :class="step === 3 ? '' : 'pt-md-12'">
                        <v-row>
                          <v-progress-linear
                            color="accent"
                            :value="progress"
                          ></v-progress-linear>
                        </v-row>
                        <v-row justify="center">
                          <span class="font-weight-bold">{{ step }} de 5</span>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>
<script>
import { validations } from "@/share/validations.js";
import { Base64 } from "js-base64";
import { mapState, mapMutations, mapActions } from "vuex";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
export default {
  components: {
    TiptapVuetify,
  },
  data() {
    return {
      affiliationPeriods: [
        { text: "Mensual", value: "MONTH" },
        { text: "Anual", value: "YEAR" },
        { text: "Trimestral", value: "TRIMESTER" },
      ],
      validCreateAffiliationForm: false,
      form: {
        name: "",
        description: "",
        dateConfiguration: {
          startDate: "",
          endDate: "",
          startTime: "",
          endTime: "",
          timeZone: "",
        },
        micrositeConfiguration: {
          link: "",
          primaryColor: "#f0f0f0",
          secondaryColor: "#000000",
          buttonColor: "#000000",
          textButtonColor: "white",
        },
        paymentModality: false,
        installments: false,
        offerBilling: false,
        comissionTransfer: false,
        affiliationTypes: [
          {
            name: "",
            accessType: "",
            inventoryType: "FINITE",
            price: "",
            quantity: "",
            details: "",
            period: "YEAR"
          },
        ],
        organization: "",
      },
      createEventButtonLoader: false,
      step: 1,
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      // Paso 9
      levelCounter: 0,
      // Validations
      affiliationNameRules: validations.generalMValidation({
        name: "nombre de membresía",
        required: true,
      }),
      modalityRules: validations.generalFValidation({
        name: "modalidad",
        required: true,
      }),
      levelNameRules: validations.requiredMValidation("nombre de boleto"),
      modalityRules: validations.requiredFValidation("modalidad"),
      levelPriceRules: validations.requiredMValidation("precio de boleto"),
      quantityRules: validations.numberFValidation({
        name: "cantidad disponible",
        required: true,
      }),
    };
  },
  created() {
    this.form.organization = this.user._id;
  },
  methods: {
    ...mapActions("affiliation", ["createAffiliation"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show"]),
    /**
     * Función para validar los campos del la creación de la membresía
     */
    validateCreateAffiliationForm() {
      if (this.$refs.createAffiliationForm.validate()) {
        this.snackbar = true;
        return true;
      }
      return false;
    },
    async _createAffiliation() {
      this.createEventButtonLoader = true;
      this.loading();
      try {
        this.form.description = Base64.encode(this.form.description);
        let response = await this.createAffiliation(this.form);
        if (response.status > 199 && response.status < 300) {
          this.show({
            text: "Membresía creada exitosamente",
            color: "primary"
          });
          this.$router.push(`/mi-membresia/micrositio/${response.data.affiliation._id}`);
        }
      } catch (error) {
        console.log(error);
        this.show({
          text: "Hubo un problema al crear la membresía",
          color: "error"
        });
      } finally {
        this.createEventButtonLoader = false;
        this.loaded();
      }
    },
    prev() {
      this.step--;
    },
    next() {
      if (this.validateCreateAffiliationForm()) {
        this.step++;
        // Reiniciar validación del formulario
        this.$refs.createAffiliationForm.resetValidation();
      }
    },
    submit() {
      alert("Form Is Submitted.");
    },
    minusLevel() {
      alert("Minus level.");
    },
    plusLevel() {
      alert("Plus level.");
    },
    /*Agregar nivel */
    addLevel() {
      if (this.validateCreateAffiliationForm()) {
        this.form.affiliationTypes.push({
          name: "",
          accessType: "",
          inventoryType: "",
          price: 0,
          quantity: "",
          details: "",
          period: "YEAR"
        });
        this.levelCounter++;
        this.$refs.createAffiliationForm.resetValidation();
      }
    },
    /*Eliminar Nivel*/
    deleteLevel() {
      let auxLevelCounter = this.levelCounter;
      if (this.levelCounter == 1) {
        this.levelCounter = 0;
      } else if (this.levelCounter > 1) {
        this.levelCounter--;
      }
      this.form.affiliationTypes.splice(auxLevelCounter, 1);
    },
    prevLevel() {
      if (this.validateCreateAffiliationForm()) {
        this.levelCounter--;
      }
    },
    nextLevel() {
      if (this.validateCreateAffiliationForm()) {
        this.levelCounter++;
      }
    },
  },
  watch: {
    "form.paymentModality": function (val) {
      if (val) {
        this.form.installments = false;
        this.form.offerBilling = false;
        this.form.affiliationTypes.forEach((element) => {
          element.price = 0;
        });
      }
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    progress() {
      return (this.step * 100) / 5;
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.line {
  transform: translateY(-235%);
}
fieldset {
  border: none;
  padding: 0;
}
/* @media (min-width: 959px) {
        .align--transform{
            position:absolute;
            top:30%;
            left:50%;
            bottom: 50%;
            height:350px;
            width:400px;
            margin-top:-100px;
            margin-left:-150px;
        }
    } */
.orientation--text {
  writing-mode: vertical-lr;
  text-orientation: mixed;
}

.v-text-field.v-text-field--solo .v-input__append-outer, .v-text-field.v-text-field--solo .v-input__prepend-outer {
  margin-top: 4px !important;
}
</style>